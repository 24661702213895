import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center max-w-[820px] mx-auto items-center">
      <h1 className="text-xl font-bold mb-4">
        Privacy Policy of EasyDiagnostic App
      </h1>
      <p className="mb-4">Effective Date: 14-05-2024</p>
      <p className="mb-4">Last updated: 14-05-2024</p>
      <section className="p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Introduction</h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          The EasyDiagnostic app is a software application for Android mobile
          phones and digital tablets designed for tracking salesmen associated
          with specific dealerships. It likely offers features such as real-time
          location tracking, performance monitoring, task assignment, and
          communication functionalities tailored to the needs of sales teams.
          With this app, managers can oversee their sales force more
          efficiently, ensuring they are where they need to be and performing
          their tasks effectively. The agreement contains the terms and
          conditions for the EasyDiagnostic App, each of which is subject to
          periodic revision. In these terms, "you," "your," and "user" refer to
          the user of the EasyDiagnostic App, and "we," "us," "our," and
          "Company" refer to MWB Technologies India Private Limited. This
          document is published in accordance with the provisions of Rule 3 (1)
          of the Information Technology (Intermediaries Guidelines) Rules, 2011
          that require publishing the terms for access or usage of
          EasyDiagnostic App. This document meets the stipulations and
          conditions mentioned in Section 65B (2) of the Indian Evidence Act,
          1872. Privacy issues are a high priority for us at MWB Technologies
          India Private Limited, and we follow standard industry practices to
          maintain your personal information. This Privacy Policy describes our
          policies and procedures for the data capture, use, and disclosure of
          your information when you use the EasyDiagnostic App. Please read the
          following to learn more about our Privacy Policy:
        </p>
      </section>

      <section className="p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">
          Interpretation and Definitions
        </h2>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Interpretation
        </h3>
        <p className=" text-gray-800 leading-relaxed">
          The words in which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          should have the same meaning, regardless of whether they appear in the
          singular or plural.
        </p>
      </section>

      <section className="p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Terms and Definitions
        </h3>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>
            <strong>"App"</strong> refers to EasyDiagnostic App in the
            agreement.
          </li>
          <li>
            <strong>"Registered user", "user", and "you"</strong> refer, as
            appropriate, to the person, business, or other legal entity that
            this person is representing when accessing or using the
            EasyDiagnostic App.
          </li>
          <li>
            <strong>"Account"</strong> refers to the unique account created for
            you to access our EasyDiagnostic App.
          </li>
          <li>
            <strong>"Privacy Policy"</strong> refers to all relevant notices,
            instructions, policies, rules, regulations, guidelines, and
            specifications that are given to you, displayed on the
            EasyDiagnostic App, or referred to in this agreement.
          </li>
          <li>
            <strong>"Device"</strong> refers to any device that can access the
            EasyDiagnostic App, including mobile phones, digital tablets,
            desktop computers, and laptops.
          </li>
          <li>
            <strong>"Personal information or data"</strong> refers to any
            information that relates to an identified or recognizable individual
            (EasyDiagnostic App user).
          </li>
          <li>
            <strong>"Usage Data"</strong> refers to data collected
            automatically, either generated by the use of the EasyDiagnostic App
            or from the EasyDiagnostic App infrastructure itself (for example,
            the duration of a page visit).
          </li>
          <li>
            <strong>"Affiliate"</strong> refers to the entity that controls, is
            controlled by, or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest, or other securities entitled to vote for the election of
            directors or other managing authority.
          </li>
          <li>
            <strong>"Company"</strong> referred to as either "we", "us" or "our"
            in this agreement refers to MWB Technologies India Private Limited.
          </li>
          <li>
            <strong>"Country"</strong> refers to India.
          </li>
          <li>
            <strong>"Third-party"</strong> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            Third-party companies or individuals employed by the Company to
            facilitate the service, provide the service on behalf of the
            Company, to perform services related to the application, or assist
            the Company in analyzing how the EasyDiagnostic App is used.
          </li>
        </ul>
      </section>
      <section className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Collecting and Using Your Personal Data
        </h2>

        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Types of Data Collected
        </h3>

        <h4 className="text-md font-semibold text-gray-900 mb-2">
          Personal Data
        </h4>
        <p className="mb-4 text-gray-800 leading-relaxed">
          While using our EasyDiagnostic App, we may ask you to provide us with
          certain personally identifiable information that can be used to
          contact or identify you. Personally identifiable information may
          include, but is not limited to:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Telephone number</li>
          <li>Mobile number</li>
          <li>Address</li>
          <li>State</li>
          <li>ZIP/Postal Code</li>
          <li>City</li>
          <li>Usage Data</li>
        </ul>

        <h4 className="text-md font-semibold text-gray-900 mb-2">Usage Data</h4>
        <p className="mb-4 text-gray-800 leading-relaxed">
          Usage Data is collected automatically when the user uses the
          EasyDiagnostic App. Usage Data may include Personal information such
          as:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>Your Device internet protocol address (e.g., IP address)</li>
          <li>Browser type and version</li>
          <li>The pages of our EasyDiagnostic App that you visit</li>
          <li>The time and date of your visit</li>
          <li>The time spent on those pages</li>
          <li>Unique Device identifiers</li>
          <li>Other diagnostic Data</li>
        </ul>
        <p className="mb-4 text-gray-800 leading-relaxed">
          When you access the EasyDiagnostic App through a Device, we may
          collect additional information, including:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>Type of Devices you use</li>
          <li>Device unique ID</li>
          <li>IP address of your Device</li>
          <li>Operating system</li>
          <li>Type of internet browser you use</li>
          <li>Unique Device identifiers</li>
          <li>Other diagnostic data</li>
        </ul>
        <p className="mb-4 text-gray-800 leading-relaxed">
          We may also collect information when you access the EasyDiagnostic App
          via a Device. Personal information collected while using the
          EasyDiagnostic App, in order to provide features of the EasyDiagnostic
          App, may be uploaded to the Company servers and/or a Third-party
          servers, or simply stored on your Device. You can enable or disable
          access to this Personal information at any time through your Device
          settings.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Use of Your Personal Data
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>
            <strong>To provide and maintain the EasyDiagnostic App:</strong>{" "}
            Including to monitor the usage of the EasyDiagnostic App.
          </li>
          <li>
            <strong>To manage your account:</strong> To manage your registration
            as a user of the EasyDiagnostic App, the Personal Data you provide
            can give you access to different functionalities of EasyDiagnostic
            App that are available to you as a Registered user.
          </li>
          <li>
            <strong>For the performance of a contract:</strong> For the
            development, compliance, and undertaking of the purchase contract
            for the products, items, or services you have purchased or of any
            other contract with us through the EasyDiagnostic App.
          </li>
          <li>
            <strong>To contact you:</strong> By email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as mobile
            applications push notifications regarding updates or informative
            communications related to functionalities, products, or contracted
            services, including security updates, when necessary or reasonable
            for their implementation.
          </li>
          <li>
            <strong>To provide you:</strong> With news, special offers, and
            general information about other applications, services, and events
            that we offer that are similar to those that you have already
            purchased or enquired about, unless you have opted not to receive
            such information.
          </li>
          <li>
            <strong>To manage your requests:</strong> To attend and manage your
            requests.
          </li>
          <li>
            <strong>For business transfers:</strong> We may use your Personal
            information to evaluate or conduct a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of our assets, whether as a going concern or
            as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by us about our EasyDiagnostic App users is among
            the assets transferred.
          </li>
          <li>
            <strong>For other purposes:</strong> We may use your Personal
            information for other purposes, such as data analysis, identifying
            usage trends, determining the effectiveness of our promotional
            campaigns, and evaluating and improving our service, products,
            marketing, and your experiences.
          </li>
        </ul>
        <p className="mb-4 text-gray-800 leading-relaxed">
          We may share your Personal information in the following situations:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>
            <strong>With Third-party:</strong> We may share your Personal
            information with Third-party to monitor and analyse the use of our
            EasyDiagnostic App and to contact you.
          </li>
          <li>
            <strong>With Affiliates:</strong> We may share your Personal
            information with our Affiliates, in which case we will require those
            Affiliates to honour this Privacy Policy. Affiliates include our
            parent Company and any other subsidiaries, joint venture partners,
            or other companies that we control or that are under common control
            with us.
          </li>
          <li>
            <strong>With business partners:</strong> We may share your Personal
            information with our business partners to offer you certain
            products, services, or promotions.
          </li>
          <li>
            <strong>With other users:</strong> Any Personal information provided
            by you will not be considered personal information if it is freely
            available and/or accessible in the public domain or is not deemed
            Personal information under the applicable law. Further, any reviews,
            comments, messages, or blogs posted, uploaded, conveyed, or
            communicated by users on the public sections of the website or an
            application store (like the App Store or Play Store) become
            published content and are not considered as Personal information
            which is subject to this Privacy Policy.
          </li>
          <li>
            <strong>With your consent:</strong> We may disclose your Personal
            information for any other purpose with your consent.
          </li>
        </ul>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Retention of Your Personal Data
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          The Company will retain your Personal Data only for as long as it is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies. The Company will also
          retain the data for internal analysis. Usage data is generally
          retained for a shorter period of time, except when this data is used
          to strengthen the security or improve the functionality of our
          EasyDiagnostic App, or when we are legally obligated to retain this
          data for longer periods.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Transfer of Your Personal Data
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this Personal
          information may be transferred and maintained on computers located
          outside of your state, province, Country, or other governmental
          jurisdiction where the data protection laws may differ from those of
          your jurisdiction. Your consent to this, followed by your submission
          of such Personal information, represents your agreement to that
          transfer. The Company will take all steps reasonably necessary to
          ensure that your Data is treated securely and in accordance with this
          Privacy Policy and no transfer of your Personal Data will take place
          to an organization or a Country unless there are adequate controls in
          place, including the security of your Personal information.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Delete Your Personal Data
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          You have the right to delete or request that we assist in deleting the
          Personal Data that we have collected about you. The EasyDiagnostic App
          may give you the ability to delete certain Personal information about
          you from within the EasyDiagnostic App. You may update, amend, or
          delete your Personal information at any time by signing in to your
          EasyDiagnostic Account, if you have one, and visiting the settings
          section that allows you to manage your Personal information. You may
          also contact us to request access to, correct, or delete any Personal
          information that you have provided to us. Please note, however, that
          we may need to retain certain information when we have a legal
          obligation or lawful basis to do so.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Disclosure of Your Personal Data
        </h2>

        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Business Transactions
        </h3>
        <p className="mb-4 text-gray-800 leading-relaxed">
          If the Company is involved in a merger, acquisition, or asset sale,
          your Personal Data may be transferred. We will provide notice before
          your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Law Enforcement
        </h3>
        <p className="mb-4 text-gray-800 leading-relaxed">
          Under certain circumstances, the Company may be required to disclose
          your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g., a court or a government agency).
        </p>

        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Other Legal Requirements
        </h3>
        <p className="mb-4 text-gray-800 leading-relaxed">
          The Company may disclose your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-800 leading-relaxed">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            EasyDiagnostic App
          </li>
          <li>
            Protect the personal safety of users of the EasyDiagnostic App
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Security of Your Personal Data
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          We take due care to protect Personal Data. Technical measures are in
          place to prevent unauthorized or unlawful access to Personal Data and
          accidental loss, destruction, or damage to the Personal Data. The
          employees who are dealing with the Personal Data have been trained to
          protect the Personal Data from any illegal or unauthorized usage. We
          maintain physical, electronic, and procedural safeguards in connection
          with the collection, storage, and disclosure of Personal Data. We take
          reasonable steps to help protect your Personal Data in an effort to
          prevent loss, misuse, unauthorized access, disclosure, alteration, and
          destruction. It is your responsibility to protect your usernames and
          passwords to help prevent anyone from accessing or abusing your
          Accounts and services. You should not use or reuse the same passwords
          you use with other accounts as your password for our EasyDiagnostic
          App. It is important for you to protect against unauthorized access to
          your password, Devices, and applications. Be sure to sign off when you
          finish using a non-personal Device. Personal information you provide
          to us via the EasyDiagnostic App is shared on our secure servers. We
          have implemented appropriate physical, technical, and organizational
          measures designed to secure your Personal information against
          accidental loss and unauthorized access, use, alteration, or
          disclosure. In addition, we limit access to Personal Data to those
          employees, agents, contractors, and other Third-parties that have a
          legitimate business need for such access. The security of your
          Personal Data is important to us, but remember that no method of
          transmission over the internet or method of electronic storage is 100%
          secure, and we cannot guarantee its absolute security.
        </p>
      </section>

      <section className="p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Usage Policy
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          We are not responsible for any losses or damages incurred by users
          accessing the EasyDiagnostic App outside of India.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Competency to Contract
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          By agreeing to this Privacy Policy, a user (which is a natural person)
          represents that the user is at least the age of majority, i.e., 18
          years old, and is competent to contract. Additionally, where the user
          is registered on behalf of, and is the authorized representative of, a
          company, partnership, or any other legal entity, such User has been
          duly authorized by such entity and is permitted under applicable law
          to accept these terms of service. If the user is a natural person who
          is not of the age of majority or is not competent to contract, the
          user may not use the EasyDiagnostic App or provide any Personal
          information to the EasyDiagnostic App.
        </p>
      </section>

      <section className="p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          User Representations
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          By agreeing to this Privacy Policy, a user (which is a natural person)
          represents that the user is at least the age of majority, i.e., 18
          years old, and is competent to contract. Additionally, where the user
          is registered on behalf of, and is the authorized representative of, a
          company, partnership, or any other legal entity, such User has been
          duly authorized by such entity and is permitted under applicable law
          to accept these terms of service. If the user is a natural person who
          is not of the age of majority or is not competent to contract, the
          user may not use the EasyDiagnostic App or provide any Personal
          information to the EasyDiagnostic App.
        </p>
      </section>

      <section className="p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Links to Other Websites or Payment Gateways
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          The EasyDiagnostic App may contain links to other websites and payment
          gateways that are not operated by us. If you click on a Third-party
          link, you will be directed to that Third-party site. We strongly
          advise you to review the Privacy Policy of every site you visit. We
          have no control over and assume no responsibility for the content,
          cookies, privacy policies, or practices of any Third-party. We accept
          no liability of whatsoever nature related to websites and payment
          gateways operated by Third-parties, and under no circumstances shall
          we be liable for any loss or damage caused by your use or reliance on
          information obtained through Third-party sites or services.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Copyright Violation
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          It includes stealing codes, programs, and other information illegally
          and creating the imitated copy by unauthorized means, utilizing the
          data either for own benefit or profit-making. This is liable for
          action as per the applicable law.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Reservation of Rights
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          MWB Technologies India Private Limited reserves the right to change,
          modify, or amend any part of the EasyDiagnostic App at any time.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Subscription
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          We have offered the users to subscribe for different versions of
          EasyDiagnostic App. Users can buy Add-ons available for EasyDiagnostic
          App to get additional features for the existing version. Users can
          subscribe to higher versions of EasyDiagnostic App to get more
          additional features by paying the applicable subscription amount. The
          Subscriptions and Add-ons have validity from the date of purchase and
          after that they will expire. All the Versions of EasyDiagnostic App
          and its features and number of Branches and users, Subscription
          Policy, Add-ons validity, are variable and subject to
          change/modified/new versions, as decided by the management of MWB
          Technologies India Private Limited from time to time. We will not be
          liable to a user or any other person for any loss or damage, whether
          direct or indirect, resulting from the termination or change of any of
          the facilities, benefits or arrangements which are made available to a
          user.
        </p>

        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Changes to This Privacy Policy
        </h2>
        <p className="mb-4 text-gray-800 leading-relaxed">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on the EasyDiagnostic
          App, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy. You are advised to
          review this Privacy Policy periodically for any changes. Changes to
          this Privacy Policy are effective when they are posted on this page.
        </p>
      </section>

      <section id="contact-us" className="py-8 px-4 md:px-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>

        <p className="text-base leading-relaxed mb-4">
          If you have any questions about this Privacy Policy, you can contact
          us in the following ways:
        </p>

        <ul className="list-disc list-inside mb-4 pl-4">
          <li className="text-base leading-relaxed">
            By writing to our support email:{" "}
            <a
              href="mailto:reachus@wbtechindia.com"
              className="text-blue-500 hover:underline"
            >
              reachus@wbtechindia.com
            </a>
          </li>
          <li className="text-base leading-relaxed">
            By visiting our website:{" "}
            <a
              href="http://www.wbtechindia.com"
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.wbtechindia.com
            </a>
          </li>
          <li className="text-base leading-relaxed">
            Address: CTS 122/141A, W B Plaza, New Cotton Market, Opp Traffic
            Police Station, Hubli, Dharwad, Karnataka, 580029
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
